<template lang="pug">
v-container(fill-height, fluid, grid-list-xl)
  v-layout(justify-center, wrap)
    v-flex(md12)
      v-layout.mt-3(row, justify-center, wrap)
        v-flex.pt-0.pb-0(md3)
          v-select(
            class="",
            outlined,
            :loading="versoes.length == 0",
            v-model="versao",
            :items="versoes",
            item-value="idversao",
            :item-text="(item) => item.versao",
            label="Versão",
            dense,
            @change="carregaClientesPorVersao"
          )

        v-flex.pt-0.pb-0(md3)
          v-text-field(
            outlined,
            v-model="data",
            type="date",
            label="Data",
            :min="data_limite",
            dense
          )

        v-flex.pt-0.pb-0(md3)
          v-text-field(
            outlined,
            v-model="hora",
            type="time",
            label="Hora",
            dense
          )

      v-layout(row, justify-center, wrap)
        v-flex.pt-0.pb-0(md6)
        v-flex.pt-0.pb-0(md3)
          v-text-field(
            v-model="search",
            outlined,
            append-icon="mdi-magnify",
            label="Pesquisar clientes",
            single-line,
            hide-details,
            dense
          )
        v-flex.pt-4.pb-2(md9)
          v-row
            v-col.pt-2.pb-0(
              cols="12",
              md="12",
              align="end",
              style="font-size: 14px"
            )
              span {{ clientes_select.length }} Cliente(s) selecionado(s)
              span
                span {{ " - " }}
                a(@click="visibilidadeListaClientes(!isOnlyClientesSelected)") {{ !isOnlyClientesSelected ? "Mostrar Selecionados" : "Mostrar Todos" }}

        v-flex.pt-0.pb-0(md9) 
          |
          v-divider.ma-0.pa-0.mt-4
          v-data-table.clientesTable(
            v-model="clientes_select",
            item-key="idcliente",
            dense,
            :search="search",
            :headers="headers",
            :items="isOnlyClientesSelected ? clientes_select : clientes",
            :items-per-page="-1",
            :footer-props="propsFooter",
            :show-select="true",
            :single-select="false",
            checkbox-color="primary",
            :loading="isLoading",
            :loading-text="isLoading ? 'Carregando clientes...' : 'Nenhum cliente encontrado'"
          )
            //- template no-data-text
            template(v-slot:no-data)
              div(style="padding-top: 130px") 
                |
                div(v-if="isOnlyClientesSelected") 
                  |
                  h5(style="color: black") Filtro aplicado: nenhum cliente selecionado
                div(v-else) 
                  |
                  h5.pb-0.mb-0(style="color: black") Escolha uma versão para carregar os clientes.
                  h5.pt-0.mt-0.pb-0.mb-0(style="color: black") Serão exibidos apenas os clientes que estão em versões anteriores à
                  h5.pt-0.mt-0(style="color: black") versão escolhida e superior a versão 2022.6.1.1
          v-divider.ma-0.pa-0

      v-layout(row, justify-center, wrap)
        v-flex.d-flex.pa-0.ma-0.pt-2(md7)
          v-spacer
          v-btn.ml-3(outlined, color="error", @click="onCancel") 
            |
            v-icon(left) mdi-close
            span Cancelar

          v-btn.ml-3(outlined, color="success", @click="onSubmit") 
            |
            v-icon(left) mdi-check
            span Confirmar
</template>

<script>
import { mapMutations } from "vuex";
import {
  versoes as versoes_api,
  clientes as clientes_api,
  versaoAgendamento as agendamento,
} from "@/api";

const dt = new Date();
dt.setDate(dt.getDate() + 1);
dt.setHours(3, 0, 0, 0);

export default {
  data: () => ({
    versao: null,
    versoes: [],
    isOnlyClientesSelected: false,
    isLoading: false,
    clientes_select: [],
    search: "",
    clientes: [],
    data: dt.toLocaleString().substr(0, 10).split("/").reverse().join("-"),
    data_limite: new Date()
      .toLocaleString()
      .substr(0, 10)
      .split("/")
      .reverse()
      .join("-"),
    hora: dt.toLocaleString().substr(11, 5),
    headers: [
      { text: "ID", value: "idcliente", width: 70, align: "center" },
      { text: "Cliente", value: "nome" },
      { text: "Hash", value: "hash", width: 200 },
      { text: "Status", value: "status", width: 200, align: "center" },
    ],
    propsFooter: {
      itemsPerPageOptions: [30, 50, 100, 200, 300, 500, -1],
      itemsPerPageAllText: "Todos",
      showCurrentPage: true,
      showFirstLastPage: true,
      itemsPerPageText: "Itens por página",
    },
  }),

  methods: {
    ...mapMutations("app", ["setTitle", "isControl"]),

    validate() {
      if (!this.versao) {
        this.$toast.error("Selecione uma versão");
        return false;
      }

      if (this.clientes_select.length == 0) {
        this.$toast.error("Selecione os clientes a serem atualizados");
        return false;
      }

      if (this.data < this.data_limite) {
        this.$toast.error("A data deve ser maior que a data atual");
        return false;
      }

      if (
        this.data == this.data_limite &&
        this.hora < new Date().toLocaleString().substr(11, 5)
      ) {
        this.$toast.error("A hora deve ser maior que a hora atual");
        return false;
      }

      return true;
    },

    async carregaVersoes() {
      versoes_api.refreshAuthProd(this.$store.state.auth);

      this.isLoading = true;
      try {
        this.versoes = await versoes_api.getDisponiveis();
      } catch (e) {
        this.$toast.error(
          "Ocorreu um erro ao tentar buscar as versões disponíveis"
        );
      } finally {
        this.isLoading = false;
      }
    },

    async carregaClientesPorVersao(versao) {
      versoes_api.refreshAuthProd(this.$store.state.auth);

      this.isLoading = true;
      try {
        const clientes = await clientes_api.getBeforeVersion(versao);

        this.clientes = clientes.map((cliente) => ({
          ...cliente,
          status:
            cliente.statusbase === 2
              ? "Inativo - com acesso somente leitura"
              : cliente.statusbase === 1
              ? "Ativo"
              : "Inativo - sem acesso",
        }));
      } catch (e) {
        this.$toast.error("Ocorreu um erro ao tentar buscar os clientes");
      } finally {
        this.isLoading = false;
      }
    },

    onSubmit() {
      const values = {
        versao: this.versao,
        clientes: this.clientes_select.map((c) => c.idcliente),
        data: this.data,
        hora: this.hora,
      };
      [];
      if (this.validate()) {
        try {
          agendamento.create(values);
          this.$toast.success("Agendamento realizado com sucesso");
          this.$router.push("/atualizacao/agenda");
        } catch (e) {
          this.$toast.error("Ocorreu um erro ao tentar agendar a atualização");
        }
      }
    },

    onCancel() {
      this.$router.push("/atualizacao/agenda");
    },

    visibilidadeListaClientes(value) {
      this.isOnlyClientesSelected = value;
      this.search = "";
    },
  },
  async mounted() {
    this.isControl(false);
    this.setTitle("Atualização de clientes");

    this.carregaVersoes();
  },
};
</script>

<style>
.clientesTable .v-data-table__wrapper {
  height: calc(100vh - 390px);
  overflow-y: auto;
}
</style>